/* You can add global styles to this file, and also import other style files */

:root {
  --background: #DDEFFF;
  --light-background: #FAFDFF;
  --lighter-background-color: #FFFFFF;
  --text-color: #27527A;
  --border-color: #CAE5FE;
  --primary-action-color: var(--border-color);
  --action-color: var(--light-background);
  --header-color: var(--text-color);
  /* --header-color: var(--text-color); */
  /* --header-text-color: var(--background); */
  --header-text-color: #FFFFFF;
  --secondary-text-color: #6987a2;
  --disabled-background: #DDDDDD;
  --disabled-color: var(--action-color);
  --hover-color: #F8F8F8;
  --disabled-background-lighter: #F8F8F8;
  --warning-color: #FFC107;
  --alert-color: #D65B6F;
  --archive-color: #A6E78E;
  --mat-table-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  scroll-behavior: smooth;
  min-height: 100%;
}

body {
  font-family: Montserrat, Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  background: url('/assets/background.svg') no-repeat, linear-gradient(var(--background) 50%, var(--lighter-background-color));
  background-size: cover;
}

ol {
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
}

h1 {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-family: Montserrat Medium, Arial, sans-serif;
  font-size: clamp(1.3rem, 5vw, 2.2rem);
  text-align: center;
}

h2 {
  padding-top: 1.0rem;
  padding-bottom: 1.0rem;
  font-family: Montserrat Medium, Arial, sans-serif;
  font-size: clamp(1.1rem, 3.8vw, 1.8rem);
  text-align: center;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-family: Montserrat Medium, Arial, sans-serif;
  font-size: clamp(2rem, 10vw, 4.5rem);
  text-align: center;
}

.mat-h2, .mat-title, .mat-typography h2 {
  padding-bottom: 1.3rem;
  font-family: Montserrat Medium, Arial, sans-serif;
  font-size: clamp(1.5rem, 6vw, 2.3rem);
  text-align: center;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  padding-bottom: 1.0rem;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 1.9rem;
  text-align: center;
}

.mat-checkbox label {
  white-space: unset;
}

.mat-table {
  font-family: unset;
}

mat-row {
  cursor: pointer;
}

mat-row.mat-mdc-row:hover {
  background: whitesmoke !important;
}

.mat-menu-content:not(:empty) {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

p {
  font-family: Montserrat, Arial, sans-serif;
  font-size: 1.3rem;
  text-align: center;
  white-space: pre-wrap;
}

.label-primary {
  font-family: Montserrat, Arial, Sans Serif;
  font-size: calc(1.05rem + 0.65vw);
  color: var(--text-color);
}

.label-primary .mat-checkbox-.label-primary {
  font-size: calc(0.7rem + 0.15vw);
}

.button-primary, .input-primary, .select-primary, .textarea-primary, .a-btn {
  font-family: Montserrat, Arial, Sans Serif;
  font-size: calc(0.8rem + 0.5vw);
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  transition: all .2s linear;
  padding: 0.8rem;
  min-height: 1.5rem;
  border: 1px solid var(--border-color);
  background-color: var(--action-color);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.button-primary, .a-btn {
  border-radius: 50px;
  width: 100%;
  box-sizing: border-box;
  height: 4.0rem;
  transition: all .2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  min-height: 3.0rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.button-primary, .a-btn>.mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-primary:disabled {
  cursor: not-allowed;
  background-color: var(--disabled-background);
  color: var(--disabled-color);
}

.button-shrinked {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: unset;
  min-height: unset;
}

.button-confirm {
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--primary-action-color);
}

.input-primary, .select-primary, .textarea-primary {
  border-radius: 15px;
  /* text-indent: 1.5vh; */
  width: 100%;
  box-sizing: border-box;
}

.textarea-primary {
  resize: vertical;
}

.select-primary {
  width: 100%;
  box-sizing: border-box;
}

.element-hint-below {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0.7rem;
  width: 100%;
  box-sizing: border-box;
}

.element-hint-below .input-primary, .select-primary {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.3rem;
}

.element-hint-below .hint-single {
  font-family: Montserrat, Arial, Sans Serif;
  font-size: calc(0.55rem + 0.4vw);
  width: 100%;
  box-sizing: border-box;
  font-style: italic;
  color: var(--secondary-text-color);
}

.element-hint-below .hint-alert {
  color: var(--alert-color);
}

.element-hint-below .hint-multi {
  font-family: Montserrat, Arial, Sans Serif;
  font-size: calc(0.55rem + 0.4vw);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-style: italic;
  color: var(--secondary-text-color);
}

.element-hint-below .hint-multi *:first-child {
  width: 80%;
  box-sizing: border-box;
}

.element-hint-below .hint-multi *:nth-child(2) {
  width: 15%;
  box-sizing: border-box;
  font-size: calc(0.5rem + 0.4vw);
  display: flex;
  justify-content: flex-end;
}

#report-states .hint-multi *, #report-states .hint-single * {
  font-size: clamp(0.5rem, 2.5vw, 0.9rem);
}

.a-btn {
  text-decoration: none;
}

.a-primary {
  font-family: Montserrat, Arial, Sans Serif;
  font-size: calc(0.9rem + 0.85vw);
  text-decoration: none;
  text-align: center;
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.icon-button {
  cursor: pointer;
}

.icon-action-button {
  cursor: pointer;
  color: var(--text-color);
  background-color: var(--background);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  transition: all .2s linear;
}

.inline-button {
  font-family: Montserrat, Arial, Sans Serif;
  font-size: calc(0.5rem + 0.5vw);
  color: var(--text-color);
  transition: all .2s linear;
  border: none;
  background-color: unset;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding-left: unset;
  padding-right: unset;
}

/* upload file */

.file-input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
}

.file-input {
  display: none;
}

.uploaded-file-container {
  display: flex;
  align-items: center;
  text-align: right;
}

/* download file */

.file-list-container {
  display: flex;
  flex-direction: column;
}

.file-list-header {
  padding-bottom: 1em;
}

.file-list-container .inline-button {
  justify-content: flex-start;
  text-align: left;
}

.button-primary:not(.disabled):hover, a:hover, .input-primary[type="button"]:hover, .icon-action-button:hover, .inline-button[type="button"]:hover {
  transform: scale(1.02, 1.02);
}

.button-primary:not(.disabled):active, .input-primary[type="button"]:active, .icon-action-button:active, .inline-button[type="button"]:active {
  transform: scale(0.95, 0.95);
}

.visual-container {
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
  height: 100%;
  background-color: var(--lighter-background-color);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
}

.visual-container-inner {
  background-color: var(--light-background);
  height: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  padding-top: 1.5rem;
  padding-left: clamp(1rem, 5vw, 15rem);
  padding-right: clamp(1rem, 5vw, 15rem);
  padding-bottom: 5rem;
  width: 90%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 1px solid var(--background);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.hint-text {
  font-size: calc(0.6rem + 0.6vw);
}

.hint-a {
  display: inline-flex;
  font-weight: bold;
}

.align-left {
  text-align: left;
}

.content-container {
  min-width: 100%;
  /* 100% minus header and footer height */
  min-height: calc(100vh - (3.8rem + 2.4vw));
  display: flex;
  justify-content: center;
  /* at least same as header => height: calc(1.9rem + 1.6vw); */
  padding-top: calc(1.9rem + 1.2vw);
}

.content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  /* padding-bottom: 15rem; */
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: clamp(1rem, 4vh, 1.5rem);
  width: clamp(28rem, 30vw, 38rem);
  max-width: 90%;
}

form label:not(.mdc-label) {
  padding-top: 1.2rem;
}

section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: clamp(1rem, 4vh, 1.5rem);
  margin: auto;
}

.above-h1-text {
  font-family: Montserrat Light, Arial, sans-serif;
  color: var(--text-color);
  padding-top: calc(1.9rem + 1.6vw);
  font-size: clamp(1rem, 4vw, 1.7rem);
  text-align: center;
  line-height: 1.2;
}

@media only screen and (max-width: 880px) {
  .filters-container {
    flex-direction: column;
  }

  .content-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
  }
}

@media only screen and (max-width: 560px) {
  nav {
    gap: 0.4rem;
  }

  form {
    gap: 0.7rem;
  }

  .content-centered {
    width: 90%;
  }
}

.header-less-tabs .mat-tab-header {
  display: none;
}

@media only screen and (min-width: 1100px) {
  #report-chat .mat-tab-header {
    margin-right: 1rem;
  }
}

#report-chat .mat-tab-group {
  align-self: stretch;
}

#report-chat section {
  margin: 1rem auto auto auto;
}

.dialog-container .mat-dialog-container {
  background-color: var(--lighter-background-color);
  color: var(--text-color);
  border-radius: 25px;
}

.rotate-180-deg {
  transform: rotate(180deg);
}

.centered {
  display: flex;
  justify-content: center;
}

.icon-large {
  font-size: x-large;
}

.icon-rotate {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}

.height-transition-container {
  transition: max-height 0.2s linear;
  overflow: hidden;
}

.height-transition-hidden {
  max-height: 0;
  animation: height-transition-opacity-backwards 0.5s ease-in;
}

.height-transition-visible {
  overflow: visible;
  animation: height-transition-opacity 0.5s ease-in;
}

@keyframes height-transition-opacity {
  from {
    opacity: 0.0;
  }

  to {
    opacity: 1.0;
  }
}

@keyframes height-transition-opacity-backwards {
  from {
    opacity: 1.0;
  }

  to {
    opacity: 0.0;
  }
}

.buttons-choice {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  justify-items: center;
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem;
  margin-bottom: 3rem;
}

.buttons-choice button {
  font-size: calc(0.8rem + 0.2vw);
}

@media only screen and (min-width: 640px) {
  .buttons-choice {
    display: grid;
    grid-template-rows: 80% 20%;
    grid-template-columns: 45% 45%;
    grid-template-areas: 'button1 button2''button3 button3';
    margin-top: 2rem;
  }

  .buttons-choice>*:nth-child(1) {
    grid-area: button1;
  }

  .buttons-choice>*:nth-child(2) {
    grid-area: button2;
  }

  .buttons-choice>*:nth-child(3) {
    grid-area: button3;
  }
}

@media only screen and (min-width: 840px) and (max-width: 1080px) {
  .buttons-choice {
    grid-template-columns: 35% 35%;
  }
}

@media only screen and (min-width: 1500px) {
  .buttons-choice {
    grid-template-columns: 35% 35%;
  }
}

.mat-radio-button:not(:last-child) {
  margin-right: 1rem;
}

.editor {
  width: 100%;
}

.editor * {
  text-align: start;
  font-size: unset;
}

.ngx-quill-view-html .ql-editor {
  padding: unset;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.ngx-quill-view-html p {
  text-align: start;
  font-size: 0.9rem;
}

.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border: 1px solid var(--border-color);
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.ql-container {
  font-family: Montserrat, Arial, sans-serif;
}

.go-back-in-states-button {
  margin-left: 2.5rem;
}

.shrinked {
  max-width: 90%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin: auto;
}

@media only screen and (min-width: 800px) {
  ol {
    padding-inline-start: 2.5rem;
    padding-inline-end: 2.5rem;
  }
}

@media only screen and (min-width: 1100px) {
  section {
    margin: unset;
  }

  .shrinked:not(.shrinked-more-width) {
    max-width: 60%;
  }
}

.shrinked label, button {
  font-size: clamp(0.5rem, 3vw, 1.0rem);
}

#notify-about-wrapper {
  width: 100%;
}

#notify-about-wrapper .shrinked {
  padding-top: unset;
}

#notify-about-wrapper .shrinked-more-width {
  padding-left: unset;
}

.right-notice {
  font-style: italic;
  color: var(--secondary-text-color);
  align-self: flex-end;
  margin-top: 1rem;
  text-align: right;
  font-size: 0.8rem;
}

.left-notice {
  align-self: flex-start;
  margin-top: 1rem;
  text-align: left;
  font-size: 0.8rem;
}

.notes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.note-notice {
  font-size: 0.8rem;
}

.note-notice span {
  text-align: left;
}

.top-left-action-button {
  max-width: 18rem;
  height: unset;
  max-height: 5rem;
  font-size: calc(0.7rem + 0.2vw);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media only screen and (min-width: 1100px) {
  .top-left-action-button {
    position: absolute;
    top: calc(1.9em + 1.2vw + 3rem);
    left: 5%;
  }
}

.no-wrap {
  white-space: nowrap;
}

dl {
  display: grid;
  grid-template-columns: 25% 75%;
  align-self: stretch;
  padding-left: 2.5rem;
  padding-right: 3.5rem;
}

dt:not(:last-child) {
  padding-bottom: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  text-align: end;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 640px) {
  dl {
    display: flex;
    flex-direction: column;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
  }

  dt:not(:first-child) {
    padding-top: 1rem;
  }

  dd {
    text-align: start;
    overflow-wrap: normal;
    margin-inline-start: unset;
  }
}

.account-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  padding-bottom: clamp(1rem, 5vw, 5rem);
}

/* Messages (chat) */

.messages-container {
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  width: 90%;
  align-self: center;
  max-height: 25rem;
  overflow: auto;
  justify-content: flex-start;
}

.message-wrapper {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message-skeleton {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 15px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  background: var(--header-color);
  color: var(--header-text-color);
}

.message-wrapper-other {
  align-self: flex-end;
  align-items: flex-end;
  margin-right: 0.5rem;
}

.message-wrapper-other .message-skeleton {
  background: var(--primary-action-color);
  color: var(--text-color);
}

.message-info {
  font-size: 0.7rem;
  color: var(--secondary-text-color);
}

.message-wrapper-my button {
  color: var(--header-text-color);
}

.message-skeleton .file-list-container {
  padding: 13px 0;
}

.message-skeleton .file-list-container .inline-button {
  justify-content: center;
}

.messages-empty-notice {
  align-self: center;
  justify-self: center;
}

/* Stepper definition below */

.stepper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 2rem;
}

.step {
  text-decoration: none;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 2.8rem auto;
  grid-template-areas: 'step-number step-label''step-line step-content';
  cursor: pointer;
  position: relative;
}

.step-no-action {
  cursor: unset;
}

.step-number {
  grid-area: step-number;
  position: relative;
  padding: 1rem;
  border-radius: 50%;
  width: 0.8rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lighter-background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.step-active .step-number {
  background-color: var(--background);
  color: var(--text-color);
  font-weight: bold;
}

.step:not(.step-no-action):hover .step-number {
  background-color: var(--hover-color);
}

.step-label {
  grid-area: step-label;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.step-line {
  grid-area: step-line;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.step-line div {
  background-color: var(--border-color);
  width: 1px;
  height: 1.2rem;
  transition: height 0.2s linear;
}

.step-content {
  grid-area: step-content;
  padding-left: 1rem;
  gap: unset;
  background-color: var(--disabled-background-lighter);
  border-radius: 25px;
  color: var(--secondary-text-color);
}

.step-content *, .step-content .ngx-quill-view-html p {
  font-size: calc(0.55rem + 0.3vw);
  text-align: left;
  color: var(--secondary-text-color);
}

.step-content p {
  padding-right: 1rem;
}

.step:last-child .step-number:after {
  display: none;
}

.step .ql-container {
  height: unset;
}

/* Categories */

.chips-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.chip-wrapper {
  padding: 0.6rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  font-size: calc(0.7rem + 0.2vw);
  min-height: 1.5rem;
}

.chip-wrapper-primary, .chip-wrapper-primary * {
  background-color: var(--background);
}

.chip-wrapper-selected, .chip-wrapper-selected * {
  background-color: var(--background);
  font-weight: bold;
}

/* Loading Symbol */

#loading-symbol-background {
  z-index: 1500;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF88;
}

.loading-symbol {
  z-index: 1501;
  display: inline-block;
  position: fixed;
  top: calc(50% - 4.2em);
  /*half screen minus half circle (8 / 2 em) minus .2 border*/
  left: calc(50% - 4.2em);
}

.loading-symbol div {
  box-sizing: border-box;
  display: block;
  position: fixed;
  width: 8em;
  height: 8em;
  border: .2em solid var(--text-color);
  border-radius: 50%;
  animation: loading-symbol 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border-color: var(--text-color) transparent transparent transparent;
}

.loading-symbol div:nth-child(1) {
  animation-delay: -0.15s;
}

.loading-symbol div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-symbol div:nth-child(3) {
  animation-delay: -0.45s;
}

@keyframes loading-symbol {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}