/* notifs styles */

:root {
    --notifs-background-color: hsl(0, 0%, 100%);
    --notifs-border-color: hsla(209, 52%, 32%, 0.2);
    --notifs-content-color: hsl(209, 52%, 32%);
    --notifs-content-size: clamp(0.8rem, calc(0.7rem + 0.9vw), 1.3rem);
    --notifs-content-opaque-color: rgba(177, 177, 177, 0.5);
    --notifs-content-type-success-color: #2eb74d;
    --notifs-content-type-error-color: #c43b48;
    --notifs-content-type-warning-color: #dba810;
    --notifs-content-type-info-color: #38a2b3;
}

.notifs-close-button {
    font-size: xx-large;
    position: absolute;
    right: 2rem;
    color: var(--notifs-content-color);
}

.cursor-pointer {
    cursor: pointer;
}

.notifs-canvas {
    display: flex;
    right: 2rem;
    bottom: 2rem;
    border-radius: 1rem;
    border: 1px solid var(--notifs-border-color);
    background-color: var(--notifs-background-color);
    position: fixed;
    overflow-x: hidden;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    
    justify-content: flex-start;
    align-items: center;
    width: clamp(10rem, 70vw, 40rem);
    min-height: clamp(4rem, calc(4rem + 1.5vw), 7rem);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.notifs-canvas-hidden {
    transform: translateX(100vw);
}

.notifs-canvas-bottom-right-corner {
    transform: translateX(0);
}

.notifs-canvas-bottom-right-corner .notifs-content {
    max-width: clamp(15rem, 70vw, 40rem);
}

#notifs-progress-bar {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--notifs-border-color);
}

.notifs-content {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    padding: unset;
    padding-left: 2rem;
    padding-right: 4rem;
    font-size: var(--notifs-content-size);
    color: var(--notifs-content-color);
}

.notifs-content-type-1 {
    color: var(--notifs-content-type-success-color);
}

.notifs-content-type-2 {
    color: var(--notifs-content-type-error-color);
}

.notifs-content-type-3 {
    color: var(--notifs-content-type-warning-color);
}

.notifs-content-type-4 {
    color: var(--notifs-content-type-info-color);
}

.notifs-content li {
    list-style: none;
}

.notifs-content li:not(:last-child) {
    padding-bottom: 3%;
}

.notifs-hidden {
    display: none;
}

.notifs-opaque {
    color: var(--notifs-content-opaque-color);
}

.notifs-blink {
    transform: scale(1.08, 1.08);
}